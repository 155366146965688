import React from "react";
import HeaderSection from "../../components/ui/HeaderSection";
import Container from "../../components/ui/Container";
// import CenterIcon from "../../components/ui/CenterIcon";
import { Icon } from "@iconify/react/dist/iconify.js";
import Button from "../../components/ui/Button";
import { useLocation } from "react-router-dom";

const HowToPlayVideo = () => {
    const { state } = useLocation();
    return (
        <>
            <div className="smooth clr-text-primary negative-nav-margin posisitve-nav-padding-top">
                <Container className="py-10 pb-10 space-y-0">
                    <HeaderSection></HeaderSection>
                </Container>
            </div>
            <Container className="relative py-20">
                <div className="flex flex-col space-y-10">
                    <Button
                        to={state && state.prevPath ? state.prevPath : "/"}
                        element="Link"
                        className="block w-full text-center font-h3 max-w-md mx-auto"
                        color="rose"
                    >
                        {state && state.prevPath ? "الرجوع للفيديو" : "الرجوع للصفحة الرئيسية"}
                    </Button>
                    <QuestionAndAnswer
                        question="الفيديوهات كانت شغاله معاك بعدين ظهر معاك انك لازم تفتح الفيديو من
                                التطبيق؟"
                        answer={`لازم تدوس على تحميل التطبيق من المتجر (Download Player) و بعد تحميل
                                التطبيق هنخش على المنصه و تفتح الفيديو و بعدين تدوس على فتح الفيديو
                                من التطبيق او (open video in player)`}
                    />
                    <QuestionAndAnswer
                        question="الفيديو شغال معاك كويس بس الشاشه كل شوية بتلاقيها بتقفل معاك و لازم تدوس على الشاشه كل شوية عشان الفيديو يفضل شغال و الشاشة متقفلش؟"
                        answer={
                            <span className="font-small">
                                <span>
                                    دي مشكلة احنا شغالين على حلها في اسرع وقت
                                    <br />
                                    بس لحد م تتحل المشكلة, ده الحل اللي ممكن تعمله عشان تقدر تشوف
                                    الفيديو بدون مشاكل :
                                    <br />
                                    <span className="font-w-bold">افتح إعدادات الجهاز:</span>
                                    <br />
                                    ابحث عن أيقونة الإعدادات (⚙️) وافتحها.
                                    <br />
                                    <span className="font-w-bold">
                                        انتقل إلى "الشاشة" أو "Display":
                                    </span>
                                    <br />
                                    ضمن قائمة الإعدادات، ابحث عن خيار يسمى "الشاشة" أو "Display". في
                                    بعض الأجهزة قد يكون هذا الخيار تحت قسم يسمى "Display &
                                    Brightness" أو "الشاشة والسطوع".
                                    <br />
                                    <span className="font-w-bold">
                                        ابحث عن خيار "مهلة الشاشة" أو "Screen Timeout":
                                    </span>
                                    <br />
                                    بمجرد دخولك إلى إعدادات الشاشة، ابحث عن خيار يسمى "Screen
                                    Timeout" أو "مهلة الشاشة" أو قد يُسمى أحيانًا "Sleep" (النوم) في
                                    بعض الأجهزة. و اختر المدة التي تناسبك
                                    <br />
                                    ستجد قائمة بالخيارات المتاحة مثل 15 ثانية، 30 ثانية، دقيقة،
                                    دقيقتين، 5 دقائق، أو حتى "عدم الإطفاء أبدًا" (على بعض الأجهزة).
                                    اختر المدة التي تناسب استخدامك، ولزيادة الوقت، اختر قيمة أعلى
                                    مثل 2 دقيقة أو 5 دقائق.
                                    <br />
                                    و بعد اختيار المدة المناسبة هتلاقي ان الشاشة مبتقفلش بعد الفترة
                                    اللي انت اخترتها
                                    <br />
                                    قد تختلف بعض المصطلحات أو مواقع الخيارات بناءً على نوع الجهاز أو
                                    واجهة المستخدم (مثل One UI في Samsung أو MIUI في Xiaomi)، ولكن
                                    هذه الخطوات هي الأكثر شيوعًا وتعمل على معظم أجهزة الأندرويد.
                                    يمكنك إضافة ملاحظة للمستخدمين بضرورة تحديث هواتفهم لأحدث إصدار
                                    متاح لتحسين الأداء والتأكد من توافق الإعدادات.
                                </span>
                            </span>
                        }
                    />
                    <QuestionAndAnswer
                        question="لو انت جهازك هواوي !"
                        answer={
                            <span>
                                <span>ازاي تحمل التطبيق و معندكش متجر بلاي (Play Store) ؟</span>
                                <br />
                                <span className="">
                                    تقدر تحمل التطبيق من{" "}
                                    <Button
                                        href="https://shorturl.at/yE3h3"
                                        element="a"
                                        target="_blank"
                                        rel="noreferrer"
                                        color="teal"
                                        className="font-small inline-block my-2 py-0 px-2"
                                    >
                                        اللينك ده
                                    </Button>
                                </span>
                                <br />
                                <span>
                                    بعد م تفتح اللينك ده هتدوس على زرار التحميل (Download) و هتنتظر
                                    حتى يتم تحميل الملف.
                                    <br />
                                    قد يطلب منك المتصفح تأكيد رغبتك في تحميل الملف. اضغط على موافق
                                    أو OK. قد يظهر تحذير أمني بأن هذا النوع من الملفات قد يكون ضارًا
                                    (لأنك تحمل تطبيقًا من خارج متجر Google Play). اضغط على موافق أو
                                    OK للمتابعة.
                                    <br />
                                    <span className="font-w-bold">فتح الملف بعد التحميل:</span>
                                    <br />
                                    بعد انتهاء التحميل، ستظهر إشعار في شريط الإشعارات الخاص بجهازك
                                    يخبرك بأن الملف جاهز.
                                    <br />
                                    اضغط على الإشعار لفتح ملف التطبيق (الملف بصيغة APK).
                                    <br />
                                    <span className="font-w-bold">
                                        تمكين "التثبيت من مصادر غير معروفة":
                                    </span>
                                    <br />
                                    إذا كانت هذه هي المرة الأولى التي تقوم فيها بتثبيت تطبيق من خارج
                                    متجر Google Play، سيطلب منك الجهاز السماح بتثبيت التطبيقات من
                                    مصادر غير معروفة. اضغط على الإعدادات أو Settings. قم بتفعيل خيار
                                    السماح بالتثبيت من هذا المصدر أو Allow from this source. ثم عد
                                    إلى شاشة التثبيت.
                                    <br />
                                    <span className="font-w-bold">تثبيت التطبيق:</span>
                                    <br />
                                    بعد تمكين التثبيت من المصادر غير المعروفة، اضغط على تثبيت أو
                                    Install. انتظر حتى يكتمل التثبيت.
                                    <br />
                                    <span className="font-w-bold font-h3">
                                        بعد م يتم تثبيت التطبيق هترجع تاني للمنصه لصفحة الفيديو و
                                        هتعمل رفرش للصفحة و هتدوس على فتح الفيديو من التطبيق او
                                        (open video in player)
                                    </span>
                                </span>
                            </span>
                        }
                    />
                    <QuestionAndAnswer
                        question="لو انت جهازك هواوي و بتدوس على open video in player مبيحصلش حاجة ؟"
                        answer={
                            <span>
                                <span>
                                    المشكلة دي بتكون بسبب انك منزل التطبيق من متجر غير موثوق زي
                                    GSPACE او G BOX أو غيره
                                </span>
                                <br />
                                <span className="font-w-bold font-h3">
                                    طب ايه الحل للمشكلة دي ؟
                                </span>
                                <br />
                                <span>
                                    هتحتاج اول حاجة :
                                    <br />
                                    - هتحتاج انك تحذف التطبيق اللي موجود على جهازك دلوقتي
                                    <br />- تاني حاجة هتحتاج تحمل التطبيق من{" "}
                                    <Button
                                        href="https://shorturl.at/yE3h3"
                                        element="a"
                                        target="_blank"
                                        rel="noreferrer"
                                        color="teal"
                                        className="font-small inline-block my-2 py-0 px-2"
                                    >
                                        اللينك ده
                                    </Button>
                                    <br />- و لو عاوز تعرف الطريقة كاملة لتحميل التطبيق من اللينك ده
                                    شوف الجزء اللي في الصفحة دي اللي فوق "# لو انت جهازك هواوي !"
                                    فيه الطريقة الكاملة لتحميل التطبيق من اللينك ده
                                </span>
                            </span>
                        }
                    />
                    <QuestionAndAnswer
                        question={`
                            بعد م حملت التطبيق بنجاح و فتحت المنصه و دوست على فتح الفيديو من التطبيق ظهر معاك ايرور في التطبيق ؟
                                `}
                        answer={
                            <span>
                                <span>على حسب الإيرور اللي ظهرلك نقدر نحل المشكلة :</span>
                                <br />
                                <br />
                                <span className="font-w-bold font-h2">
                                    لو رقم المشكلة 9201 او 9202 :{" "}
                                </span>
                                <br />
                                <br />
                                <span>
                                    ده معناه ان الجهاز عندك متفعل فيه خاصية اسمها وضع المطور
                                    (Developer Mode) و دي خاصية بتمنع الفيديو من انه يشتغل في الجهاز
                                    الخاص بيك
                                    <br />
                                    أو انت مفعل خاصية في الجهاز عندك اسمها وضع تصحيح USB
                                    <br />
                                    (USB Debugging)
                                </span>{" "}
                                <br />
                                <span className="font-w-bold">طب و الحل ؟</span>
                                <br />
                                <span>
                                    الحل انك لازم تدخل عندك على الجهاز و تبحث عن الخاصيتين دول و
                                    تقفلهم
                                </span>
                                <br />
                                <span className="font-w-bold">طب و اقفلهم ازاي ؟</span>
                                <br />
                                <br />
                                <span className="font-w-bold">
                                    لو انت المشكلة اللي ظاهره عندك 9201 ده معناه انك مفعل خاصية وضع
                                    المطور (Developer Mode) و عشان تقفلها :
                                </span>
                                <br />
                                <span>
                                    هتخش على الإعدادات عند و لو الجهاز عند باللغة العربية هتبحث عن
                                    حاجة اسمها خيارات المطور و تقفلها و لو الجهاز عند باللغة
                                    الانجليزية هتبحث عن حاجة اسمها Developer Mode او Developer
                                    Options و تقفلها
                                </span>
                                <br />
                                <br />
                                <span className="font-w-bold">
                                    طب لو انت المشكلة اللي ظاهره عندك 9202 ده معناه انك مفعل خاصية
                                    تصحيح ال USB او USB Debugging و عشان تقفلها :
                                </span>
                                <br />
                                <span>
                                    هتخش على الاعدادات عندك و هتدور على حاجة اسمها رقم الإصدار او
                                    رقم البناء او (Build Number) و دي على حسب الجهاز بتاعك ممكن
                                    تلاقيها في (About Device) و هتلاقيها جوا ال (Software
                                    Information)
                                    <br />
                                    و بعدين هتدوس على رقم الإصدار سبع مرات , هتلاقي تم تفعيل حاجة
                                    اسمها خيارات المطور او ال Developer Mode Is Enabled
                                    <br />
                                    بعدين هترجع برا في الإعدادات و تسيرش على خيارات المطور او ال
                                    developer options و هتدور جواها على حاجة اسمها تصحيح USB أو USB
                                    Debugging و تقفلها
                                    <br />و بعدين هتقفل خيارات المطور او Developer Options كلها
                                </span>
                                <br />
                                <span>
                                    و تقدر تشوف برضه فيديو من هنا عن طريقة حل المشكلتين دول بالكامل
                                    :
                                </span>
                                <Button
                                    element="a"
                                    href="https://youtube.com/shorts/r5tQepIiWIA?si=hUBFcW474jsc0i0n"
                                    target="_blank"
                                    rel="noreferrer"
                                    color="rose"
                                >
                                    فتح فيديو حل مشكلة 9201 / 9202{" "}
                                </Button>
                                <br />
                                <br />
                                <span className="font-w-bold font-h2">
                                    طب لو رقم المشكلة كانت #4001
                                </span>
                                <br />
                                <br />
                                <span>
                                    دي كانت مشكلة مؤقتة في التطبيق و تم حلها في آخر تحديث للتطبيق
                                    موجود على متجر بلاي (Play Store)
                                </span>
                                <br />
                                <span className="font-w-bold font-h2">
                                    طب لو لسة المشكلة دي ظاهره معاك ؟
                                </span>
                                <br />
                                <span>
                                    ده معناه ان التطبيق عندك لم يتم تحديثه لآخر اصدار موجود على متجر
                                    بلاي (Play Store)
                                </span>
                                <br />
                                <span className="font-w-bold">طيب احدث ازاي التطبيق ؟</span>
                                <br />
                                <span>
                                    هتخش على متجر بلاي (Play Store) و تدور على التطبيق و تدوس على
                                    تحديث (Update)
                                    <br />
                                    أو هتحذف التطبيق من جهازك و تحمله تاني من متجر بلاي (Play Store)
                                    <br />
                                    لو مش لاقي التطبيق على متجر بلاي,{" "}
                                    <Button
                                        href="https://play.google.com/store/apps/details?id=com.inkryptvideos.ink_player&hl=en"
                                        element="a"
                                        target="_blank"
                                        rel="noreferrer"
                                        color="teal"
                                        className="font-small inline-block my-2"
                                    >
                                        اضغط هنا للتحميل من متجر بلاي
                                    </Button>
                                </span>
                                <br />
                                <br />
                                <span className="font-w-bold font-h2">
                                    خطوات حل مشكلة ايرور #6004 بسبب اعدادات توقيت مختلفة :
                                </span>
                                <br />
                                <br />
                                <span>
                                    ١- اذهب لاعدادات الهاتف settings
                                    <br />
                                    ٢- ابحث عن اعدادات التاريخ Date
                                    <br />
                                    ٣- قم بتفعيل خيار ضبط التاريخ و الوقت تلقائيا / set
                                    automatically
                                    <br />
                                </span>
                                <span className="font-w-bold font-h2">لو ظهر معاك ايرور #6006</span>
                                <br />
                                <br />
                                <span>
                                    هتكون محتاج تعمل ريستارت للجهاز و تجرب تاني و لو لسة متحلتش ممكن
                                    تجرب حذف الأبلكيشن و تحميله تاني من الأول و لو لسة متحلتش تقدر
                                    تكلمنا على الدعم
                                </span>
                                <br />
                                <br />
                                <span className="font-w-bold font-h2">
                                    طب لو رقم المشكلة كانت #5000 أو #20
                                </span>
                                <br />
                                <br />
                                <span>
                                    دي بتبقا مشكلة في النت او الشبكه في الجهاز الخاص بيك هتكون محتاج
                                    انك تغير شبكه النت و لو لسة متحلتش ممكن يكون عندك في الجهاز حاجة
                                    بتسحب نت زي ابديت للجهاز او اي ابديت
                                    <br />
                                    هتكون محتاج تتأكد ان مفيش حاجة بتحسب النت في الجهاز الخاص بيك او
                                    تعمل ريستارت للجهاز و تجرب تاني
                                </span>
                            </span>
                        }
                    />
                    <QuestionAndAnswer
                        question="لو جربت الحلول دي او ظهرتلك مشكلة تانية غير المشاكل دي ؟"
                        answer={`تقدر تكلمنا على الدعم بس حاول توصل مشكتلك بوضوح عشان نقدر نحللك مشكتلك بالظبط منغير لغبطة و بدون اي تأخير`}
                    />
                    <Button
                        to={state && state.prevPath ? state.prevPath : "/"}
                        element="Link"
                        className="block w-full text-center font-h3 max-w-md mx-auto"
                        color="rose"
                    >
                        {state && state.prevPath ? "الرجوع للفيديو" : "الرجوع للصفحة الرئيسية"}
                    </Button>
                </div>
            </Container>
        </>
    );
};

const QuestionAndAnswer = ({ question, answer }) => {
    return (
        <div className="flex flex-col space-y-3">
            <div className="font-w-bold font-h2 flex items-center space-x-2 space-x-reverse">
                <Icon icon="line-md:question-circle-twotone" className="transform font-big" />
                <span>{question}</span>
            </div>
            <div className="flex items-center space-x-2 space-x-reverse">
                <Icon
                    icon="line-md:question-circle-twotone"
                    className="transform font-big opacity-0"
                />
                <span>{answer}</span>
            </div>
        </div>
    );
};

export default HowToPlayVideo;
